import { Switch } from 'react-router-dom';
import * as p from 'pages';
import { useAuth } from 'hooks';
import Route from './Route';

const Routes: React.FC = () => {
  const { company } = useAuth();

  return (
    <Switch>
      {/* Non private routes */}
      <Route path="/" exact component={p.Auth.SignIn} />
      <Route exact path="/register" component={p.Auth.SignUp} />
      <Route
        exact
        path="/login/2fa"
        component={p.Auth.TwoFactorAuthentication}
      />
      <Route exact path="/terms-of-use" component={p.Terms} defaultLayout />
      {/* <Route exact path="/password/reset" component={p.Auth.ResetPassword} /> */}
      {/* Private routes */}
      <Route exact path="/survey" component={p.Survey} isPrivate />
      <Route exact path="/onboard" component={p.Onboard} isPrivate />
      <Route exact path="/dashboard" component={p.Dashboard} isPrivate />
      {/* <Route
        exact
        path="/chat"
        component={p.Chat}
        isPrivate
        roles={['customer_success_role']}
        disabled={!company?.has_partner_chat}
      /> */}
      <Route exact path="/stamps" component={p.Stamps} isPrivate />
      <Route
        exact
        path="/stamps/benefits"
        component={p.StampBenefits}
        isPrivate
      />
      <Route exact path="/products" component={p.Products} isPrivate />
      <Route exact path="/customers" component={p.Customers} isPrivate />
      <Route
        exact
        path="/customers/create"
        component={p.CustomerCreate}
        isPrivate
      />
      <Route
        exact
        path="/customers/:id"
        component={p.CustomerDetail}
        isPrivate
      />
      <Route
        exact
        path="/customers/plan/:id"
        component={p.CustomerCreate}
        isPrivate
      />
      <Route
        exact
        path="/invoices"
        component={p.Invoices}
        isPrivate
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/external/:hash"
        component={p.NewInvoiceExternal}
        defaultLayout
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/bank-slip/:hash"
        component={p.InvoiceBankslip}
        defaultLayout
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/create"
        component={p.InvoiceCreate}
        isPrivate
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/:id"
        component={p.InvoiceDetail}
        isPrivate
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path={['/suporte', '/tickets']}
        component={p.Tickets}
        isPrivate
        disabled={company?.is_provider}
      />
      <Route
        exact
        path={['/suporte/create', '/tickets/create']}
        component={p.TicketCreate}
        isPrivate
        disabled={company?.is_provider}
      />
      <Route
        exact
        path={['/suporte/:id', '/tickets/:id']}
        component={p.TicketDetail}
        isPrivate
        disabled={company?.is_provider}
      />
      {/* <Route
        path="/migrations"
        exact
        component={p.MigrationListingNew}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/create"
        exact
        component={p.MigrationCreateNew}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/:id"
        exact
        component={p.MigrationDetailNew}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/create/website"
        exact
        component={p.MigrationCreateWebsiteNew}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/create/email"
        exact
        component={p.MigrationCreateEmailNew}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/create/assisted"
        exact
        component={p.MigrationCreateAssistedNew}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      /> */}
      <Route
        exact
        path="/plans"
        component={
          company?.provider.includes('partner-g3') ||
          company?.provider.includes('partner-g4') ||
          company?.provider.includes('dpg-custom')
            ? p.PlansNew
            : p.Plans
        }
        isPrivate
      />
      <Route
        exact
        path="/atualizacao-preco"
        component={p.NewPrices}
        isPrivate
      />
      <Route
        exact
        path="/transfers"
        component={p.Transfers}
        isPrivate
        roles={['financial_role']}
      />
      <Route exact path="/academy" component={p.CloudezAcademy} isPrivate />
      <Route
        exact
        path="/downloads"
        component={p.Downloads}
        isPrivate
        roles={['financial_role']}
      />
      <Route
        exact
        path="/company"
        component={p.Company}
        isPrivate
        roles={['company_owner_role']}
      />
      {/* 404 Route */}
      <Route path="/404" exact component={p.NotFound} isPrivate />
      <Route component={p.NotFound} />{' '}
    </Switch>
  );
};

export default Routes;
