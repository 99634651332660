import React from 'react';

import { Header, Title } from 'components/Common';
// import { DownloadCard } from './styles';

import { Row, Col } from 'react-bootstrap';
import { faFilePdf, faFileWord } from '@fortawesome/free-solid-svg-icons';

import { faElementor } from '@fortawesome/free-brands-svg-icons';
import { useDocTitle } from 'hooks';
import DownloadCard from './DownloadCard';

const Downloads = () => {
  useDocTitle('Downloads - Cloudez Partners');
  return (
    <>
      <Header>
        <Title>Downloads</Title>
      </Header>

      <Row
        style={{
          width: '100%',
          marginBottom: '30px',
        }}
      >
        <Col xl="3">
          <DownloadCard
            filename="Material de Apoio - Informações Técnicas.pdf"
            icon={faFilePdf}
            title="Material de Apoio - Informações Técnicas"
            desc="Detalhes importantes do funcionamento da nossa automação"
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Estratégias de Precificação para Planos Cloudez.pdf"
            icon={faFilePdf}
            title="Estratégias de Vendas por Tipo de Cloud"
            desc="Entenda como precificar os planos de hospedagem pelo tipo de cloud"
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Contrato de Prestação de Serviços.docx"
            icon={faFileWord}
            title="Minuta de Contrato de Serviços"
            desc="Modelo de minuta de contrato para utilização como base. Mais segurança jurídica para todos."
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="LP-Parceiro-Cloudez-Elementor.json"
            icon={faElementor}
            title="Landing Page Whitelabel"
            desc="Modelo de página de captura com conteúdo pronto em Elementor para entrega de valor ao cliente final."
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Premissas Estratégicas de Negócios.pdf"
            icon={faFilePdf}
            title="Premissas Estratégicas de Negócios"
            desc="Tenha o mindset correto de negócios para prospectar no digital."
          />
        </Col>

        <Col xl="3">
          <DownloadCard
            filename="Aplicações por Cloud.pdf"
            icon={faFilePdf}
            title="Aplicações por Cloud"
            desc="Saiba como decidir como hospedar cada cliente ou projeto seu."
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Cases de Resultados.pdf"
            icon={faFilePdf}
            title="Cases de Resultados"
            desc="Conheça alguns cases de resultados dos parceiros Cloudez."
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Sugestões Precificação.pdf"
            icon={faFilePdf}
            title="Sugestões Precificação"
            desc="Boas práticas sobre precificação do nosso programa de parceria Cloudez."
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Apresentação de Negócios da Cloudez.pdf"
            icon={faFilePdf}
            title="Apresentação de Negócios da Cloudez"
            desc="Uma visão geral da nossa apresentaão insitucional."
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Critérios de Desempenho.pdf"
            icon={faFilePdf}
            title="Critérios de Desempenho"
            desc="Saiba quais são os critérios de desempenho"
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Sugestões Tecnológicas.pdf"
            icon={faFilePdf}
            title="Sugestões Tecnológicas"
            desc="Algumas sugestões tecnológicas para te auxiliar na sua decisão."
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Welcome Kit.pdf"
            icon={faFilePdf}
            title="Welcome Kit"
            desc="Informações essenciais para novos parceiros, benefícios da parceria e as melhores práticas para utilização da plataforma."
          />
        </Col>
        <Col xl="3">
          <DownloadCard
            filename="Manual do Parceiro.pdf"
            icon={faFilePdf}
            title="Manual do Parceiro"
            desc="Veja o passo a passo da parceria, os times que você vai conhecer nos primeiros meses e o acompanhamento que a Cloudez dispõe para a sua agência."
          />
        </Col>
      </Row>
    </>
  );
};

export default Downloads;
