import { useEffect, useMemo, useState } from 'react';

import history from 'services/history';

import { Link, useLocation, withRouter } from 'react-router-dom';

import { lighten } from 'polished';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandsHelping, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useAuth, useLayout, useNotifications } from 'hooks';

import SidebarTag from 'pages/_layouts/default/Sidebar/SidebarTag';

import { ReactComponent as Stamp } from 'assets/img/svg/stamp-icon.svg';
import { ReactComponent as academyLogo } from 'assets/img/svg/academyLogo.svg';
import cybeWeek from 'assets/img/cybe-week.png';

import {
  Container,
  MenuList,
  MenuItem,
  SubMenuItem,
  Notification,
} from './styles';
import Logo from './Logo';
import CommercialModal from './CommercialModal';

const Sidebar = () => {
  const { sideMenuCollapsed, toggleMenu, brand_primary_color } = useLayout();
  const { user, company, signOut } = useAuth();
  const { notifications } = useNotifications();
  const [modal, setModal] = useState(false);
  const theme = useLayout();
  const location = useLocation();

  const today = moment();
  const startDateEventAt = moment('2021-12-04');
  const isDateEvent =
    startDateEventAt && startDateEventAt.isAfter(today, 'days');

  const endDateEventAt = moment('2021-11-28');
  const isEndDateEvent =
    endDateEventAt && endDateEventAt.isBefore(today, 'days');

  const isAuthenticated = useMemo(() => !!user, [user]);

  const isActivated = useMemo(
    () => user?.is_staff || (!company?.is_blocked && company?.status === 2),
    [user, company],
  );

  const items = useMemo(() => {
    if (isActivated)
      return [
        { to: '/customers', name: 'Clientes', icon: 'users' },
        { to: '/plans', name: 'Planos', icon: 'dollar-sign' },
        {
          to: '/invoices',
          name: 'Faturas',
          icon: 'barcode',
          roles: ['financial_role', 'customer_success_role'],
        },
      ];

    return [
      {
        to: '/invoices',
        name: 'Faturas',
        icon: 'barcode',
        roles: ['financial_role', 'customer_success_role'],
      },
      {
        to: '/company',
        name: 'Empresa',
        icon: 'building',
        roles: ['company_owner_role'],
      },
    ];
  }, [isActivated]);

  const subItems = useMemo(
    () =>
      isActivated && [
        {
          to: '/transfers',
          name: 'Extrato',
          icon: 'list-alt',
          roles: ['financial_role'],
        },
        {
          to: '/suporte',
          name: 'Suporte',
          icon: 'comment-alt',
          disabled: company?.is_provider,
        },
        // {
        //   to: '/migrations',
        //   name: 'Migrações',
        //   icon: 'exchange-alt',
        //   roles: ['technical_role', 'customer_success_role'],
        // },
        {
          to: '/company',
          name: 'Empresa',
          icon: 'building',
          roles: ['company_owner_role'],
        },
        {
          to: '/downloads',
          name: 'Downloads',
          icon: 'download',
          roles: ['financial_role'],
        },
        {
          to: 'https://comunidade.cloudez.io/',
          name: 'Comunidade',
          icon: 'handshake',
          external: true,
          roles: ['company_owner_role'],
        },
        {
          to: '/academy',
          name: 'Academy',
          new: false,
          icon: academyLogo,
          notFontawesome: true,
        },
        // {
        //   to: '/chat',
        //   name: 'Chat',
        //   icon: 'comment-dots',
        //   disabled: !company?.has_partner_chat,
        //   roles: ['customer_success_role'],
        // },
        {
          to: '/stamps',
          name: 'Selos',
          new: false,
          icon: Stamp,
          notFontawesome: true,
          roles: ['company_owner_role'],
        },
        {
          to: '/products',
          name: 'Produtos e Serviços',
          new: true,
          icon: 'dolly',
        },
      ],
    [isActivated, company],
  );

  const closeSidenav = e => {
    if (window.innerWidth <= 768) {
      e.stopPropagation();
      toggleMenu();
    }
  };

  const migNotifications = notifications?.filter(
    n => n && n.type === 'migration' && n.status === 0,
  );

  const ticketNotifications = notifications?.filter(
    n => n && n.type === 'ticket' && n.status === 0,
  );

  return (
    <Container sideMenuCollapsed={sideMenuCollapsed}>
      {!company?.is_provider && modal && (
        <CommercialModal setShow={() => setModal(false)} show={modal} />
      )}
      <Logo />

      {isAuthenticated && location.pathname !== '/survey' && (
        <MenuList>
          {items.map(
            (route: any) =>
              !route.disabled && (
                <MenuItem
                  isActive={history.location.pathname.includes(route.to)}
                  key={route.icon}
                  disabled={
                    route.roles &&
                    !route.roles.some(role => user.roles.includes(role)) &&
                    !user.roles.includes('company_owner_role') &&
                    !user.roles.includes('god_role') &&
                    !user.roles.some(role => role.includes('admin_'))
                  }
                >
                  {route.new && <SidebarTag>NOVO</SidebarTag>}
                  <Link to={route.to} onClick={closeSidenav}>
                    {route.notFontawesome ? (
                      <route.icon className="not-fontawesome" />
                    ) : (
                      <FontAwesomeIcon icon={route.icon} />
                    )}
                    <span>{route.name}</span>
                  </Link>
                </MenuItem>
              ),
          )}

          <div
            style={{
              width: '100%',
              padding: '0.625rem 0',
              backgroundColor: lighten(0.07, brand_primary_color),
            }}
          >
            {subItems &&
              subItems.map((route, i) => {
                const hasNot =
                  (route.to === '/suporte' && ticketNotifications) ||
                  (route.to === '/migrations' && migNotifications);
                return (
                  !route.disabled && (
                    <SubMenuItem
                      isActive={history.location.pathname.includes(route.to)}
                      // key={route.icon}
                      first={i === 0}
                      last={
                        !company?.has_partner_chat
                          ? i === subItems.length - 2
                          : i === subItems.length - 1
                      }
                      disabled={
                        route.roles &&
                        !route.roles.some(role => user.roles.includes(role)) &&
                        !user.roles.includes('company_owner_role') &&
                        !user.roles.includes('god_role') &&
                        !user.roles.some(role => role.includes('admin_'))
                      }
                    >
                      {route.new && !sideMenuCollapsed && (
                        <SidebarTag>NOVO</SidebarTag>
                      )}
                      {route.external ? (
                        <a href={route.to} target="_blank" rel="noreferrer">
                          {route.notFontawesome ? (
                            <route.icon className="not-fontawesome" />
                          ) : (
                            <FontAwesomeIcon icon={route.icon as IconProp} />
                          )}
                          <span>{route.name}</span>
                        </a>
                      ) : (
                        <Link to={route.to} onClick={closeSidenav}>
                          {route.notFontawesome ? (
                            <route.icon
                              id={route.name === 'Selos' ? 'stamp' : ''}
                              className="not-fontawesome"
                            />
                          ) : (
                            <FontAwesomeIcon icon={route.icon as IconProp} />
                          )}
                          {hasNot?.length > 0 && (
                            <Notification first={i === 0}>
                              {hasNot.length}
                            </Notification>
                          )}
                          <span
                            style={{
                              textAlign: 'left',
                              lineHeight:
                                route.name === 'Produtos e Serviços' && '20px',
                            }}
                          >
                            {route.name}
                          </span>
                        </Link>
                      )}
                    </SubMenuItem>
                  )
                );
              })}
          </div>

          {isDateEvent && isEndDateEvent ? (
            <>
              {!company?.is_provider && user?.is_company_owner && (
                <MenuItem apoio onClick={() => setModal(true)}>
                  <img
                    src={theme.sideMenuCollapsed ? cybeWeek : cybeWeek}
                    alt="cybeWeek"
                    style={{
                      width: 62,
                      marginBottom: 10,
                    }}
                  />

                  <span
                    style={{
                      fontWeight: 'bold',
                      color: '#fff',
                      fontSize: 14,
                      lineHeight: '18px',
                    }}
                  >
                    Cyber Week da Cloudez
                  </span>
                  <span
                    style={{
                      fontWeight: 'normal',
                      color: '#fff',
                      fontSize: 14,
                      lineHeight: '18px',
                      marginTop: '8px',
                    }}
                  >
                    NÃO PERCA! Somente até dia 03/12
                  </span>
                </MenuItem>
              )}
            </>
          ) : (
            <>
              {!company?.is_provider && user?.is_company_owner && (
                <MenuItem apoio onClick={() => setModal(true)}>
                  <div className="card">
                    <FontAwesomeIcon flip="horizontal" icon={faHandsHelping} />
                    <span style={{ opacity: 1 }}>
                      Preciso fazer <br /> uma venda
                    </span>
                  </div>
                </MenuItem>
              )}
            </>
          )}

          <MenuItem logout>
            <p onClick={() => signOut()}>
              <FontAwesomeIcon icon={faPowerOff} />
              <span>Sair</span>
            </p>
          </MenuItem>
        </MenuList>
      )}
    </Container>
  );
};

export default withRouter(Sidebar);
